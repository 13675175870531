import { BiblioteksentralenBaseCss } from "@biblioteksentralen/js-utils";
import { AppProps } from "next/app";
import React, { ReactNode } from "react";
import { EditInSanityContextProvider } from "../components/editInSanity/EditInSanityContext";
import { PreviewContextProvider } from "../components/preview/previewContext";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme } from "../chakraTheme/chakraTheme";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import { SOMEPreview } from "../components/preview/SOMEPreview";
import { logVercelInfo } from "../utils/logVercelInfo";
import { useMount } from "react-use";

function Providers(props: { children: ReactNode }) {
  return (
    <ChakraProvider theme={chakraTheme}>
      <PreviewContextProvider>
        <EditInSanityContextProvider>{props.children}</EditInSanityContextProvider>
      </PreviewContextProvider>
    </ChakraProvider>
  );
}

function App({ Component, pageProps }: AppProps) {
  useMount(() => logVercelInfo());

  return (
    <Providers>
      <BiblioteksentralenBaseCss />
      <Testenvironmentbanner />
      <Component {...pageProps} />
      <SOMEPreview />
    </Providers>
  );
}

export default App;
