import { ComponentStyleConfig } from "@chakra-ui/theme";

export const LinkStyles: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: "underline",
    _hover: {
      textDecoration: "none",
    },
  },
  variants: {
    plain: {
      textDecoration: "none",
      _hover: {
        textDecoration: "underline",
      },
    },
  },
};
