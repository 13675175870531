import { ChakraTheme } from "@chakra-ui/theme";
import { extendTheme } from "@chakra-ui/react";
import { LinkStyles } from "./LinkStyles";

const chakraThemeCustomization: Partial<ChakraTheme> = {
  styles: {
    global: {
      html: {
        fontSize: ["107.5%", null, "115%"],
      },
    },
  },
  fonts: {
    body: "'Source Sans Pro',sans-serif",
    heading: "'Source Sans Pro',sans-serif",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "600",
      },
    },
    Link: LinkStyles,
  },
};

export const chakraTheme = extendTheme(chakraThemeCustomization);
