import { Link } from "@chakra-ui/react";
import { Flex, HStack, Text } from "@chakra-ui/layout";
import React from "react";
import { useSanityAuth } from "../sanityAuth/useSanityAuth";
import { usePreviewContext } from "./previewContext";
import { somePreviewID } from "./SOMEPreview";
import { css, Global } from "@emotion/react";
import NextLink from 'next/link';

/* Kan slettes etter bump av js-utils til 0.8.1 */
const globalStyles = css` 
  html:focus-within {
    // Sørger for smooth scrolling for interne lenker som scroller til annet sted på siden, men ikke hvis fokus er utenfor viduet, feks ved søk i tekst (ctrl + f)
    scroll-behavior: smooth;
  }
`;

function PreviewBanner() {
  const context = usePreviewContext();
  const authStatus = useSanityAuth();

  if (!context.isOn) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      backgroundColor="hsl(0, 100%, 38%, 0.6)"
      color="white"
      fontSize="0.8rem"
      padding="0.25rem"
      fontWeight="600"
      position="sticky"
      top="0"
      zIndex="1000"
    >
      {authStatus.isAuthenticated ? (
        <HStack>
          <NextLink href={`#__next`} passHref><Link>Forhåndsvisning</Link></NextLink>
          <NextLink href={`#${somePreviewID}`} passHref><Link>Sosiale medier</Link></NextLink>
          <Global styles={globalStyles} />
        </HStack>
      ) : (
        <Text>
          Du må{" "}
          <Link inheritColor href="/cms/">
            logge inn
          </Link>{" "}
          for forhåndsvisning
        </Text>
      )}
    </Flex>
  );
}

export default PreviewBanner;
