import { Box, Container, Image, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { usePreviewContext } from "./previewContext";
import { Heading, Text } from "@chakra-ui/react";
import { colors, guid, HashLinkTarget } from "@biblioteksentralen/js-utils";
import { useRouter } from "next/router";
import { css } from "@emotion/react";

export const somePreviewID = guid()
interface Metadata {
  image?: string;
  title?: string;
  host?: string;
  description?: string;
}

export function SOMEPreview() {
  const previewContext = usePreviewContext();
  const [metadata, setMetadata] = useState<Metadata | undefined>();
  const router = useRouter();

  useEffect(() => {
    if (!previewContext.isOn) return;
    const updateMetadata = () => setMetadata(getMetdata());
    updateMetadata();
    const interval = setInterval(updateMetadata, 5000);
    return () => clearInterval(interval);
  }, [router.asPath, previewContext.isOn]);

  if (!previewContext.isOn) return null;

  return (
    <Box borderTop={`solid ${colors.accentYellow} .2rem`} backgroundColor={colors.alertYellow}>
      <Container maxW="container.lg" padding="4rem 1rem">
        <Heading as="h2" fontSize="lg">
          <HashLinkTarget id={somePreviewID} />
          Forhåndsvisning ved deling i sosiale medier
        </Heading>
        <Text fontSize=".9rem" opacity=".8">
          Facebook og andre sosiale medier styrer selv hvordan layout på forhåndsvisning blir, men sansynligvis vil det
          ligne på en av disse forhåndsvisningene:
        </Text>
        <Stack maxW="35rem" marginY="2rem" spacing="1rem">
          <Stack
            direction="row"
            backgroundColor="white"
            boxShadow="0 .5rem 1rem #8884"
            borderRadius="md"
            padding="1rem"
            justifyContent="space-between"
            spacing=".75rem"
          >
            <Box>
              <Text fontWeight={600}>{metadata?.host}</Text>
              <Heading as="h3" fontSize="md" color="blue.600">
                {metadata?.title}
              </Heading>
              <Text fontSize={{ base: "sm", md: "md" }} noOfLines={2}>
                {metadata?.description}
              </Text>
            </Box>
            <Image borderRadius="md" objectFit="cover" height="6rem" width="6rem" src={metadata?.image} alt="" />
          </Stack>
          <Stack
            backgroundColor="white"
            boxShadow="0 .5rem 1rem #8884"
            borderRadius="md"
            padding="1rem"
            justifyContent="space-between"
          >
            <Image
              borderRadius="md"
              objectFit="cover"
              css={css`
                aspect-ratio: 1.9/1;
              `}
              src={metadata?.image}
              alt=""
            />
            <Box>
              <Text fontWeight={600}>{metadata?.host}</Text>
              <Heading as="h3" fontSize="md" color="blue.600">
                {metadata?.title}
              </Heading>
              <Text noOfLines={2}>{metadata?.description}</Text>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

// Henter ut data på denne måten fordi vi prøver å simulere hvordan Facebook/google etc henter ut metadata fra sidene våre
function getMetdata(): Metadata {
  const title = document.querySelector("title")?.innerText.split("|")[0]; // henter data fra <title>Min tittel</title>
  // @ts-ignore
  const description = document.querySelector('meta[name="description"]')?.content; // henter data fra <meta name="description" content="Min beskrivelse"/>
  // @ts-ignore
  const image = document.querySelector('meta[property="image"]')?.content; // henter data fra <meta property="image" content="https://myimage.jpg" />
  // @ts-ignore
  const canonical = document.querySelector('link[rel="canonical"]')?.href; // henter data fra <link rel="canonical" href="https://gjeldendeUrl" />

  return { description, image, title, host: getHost(canonical) };
}

function getHost(url?: string) {
  try {
    return new URL(url || "").host;
  } catch {
    return "";
  }
}
